<template>
  <div>
    <!-- BREADCRUMB -->
    <section
      class="breadcrumb-bg"
      style="background-image: url(../assets/img/background/page-title-bg-img.jpg)"
    >
      <div class="container">
        <div class="breadcrumb-holder">
          <div>
            <h1 class="breadcrumb-title">FAQ’S</h1>
            <ul class="breadcrumb breadcrumb-transparent">
              <li class="breadcrumb-item">
                <a class="text-white" href="/">Home</a>
              </li>
              <li class="breadcrumb-item text-white active" aria-current="page">
                FAQ’S
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- content -->
    <section class="py-6 py-md-8">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-lg-3">
            <div class="card shadow-none bg-transparent">
              <h4
                class="card-header font-weight-bold bg-success rounded-top text-white"
              >
                問題分類
              </h4>
              <div class="card-body border border-top-0 rounded-bottom">
                <ul class="list-unstyled mb-0">
                  <li class="mb-2">
                    <router-link to="/faq/register"
                      class="text-muted font-weight-medium d-block border rounded py-2 pl-3"
                      >
                      會員註冊相關問題
                    </router-link>
                  </li>
                  <li class="my-2">
                    <router-link to="/faq/game-rule"
                      class="text-muted font-weight-medium d-block border rounded py-2 pl-3"
                      >
                    遊戲規則說明
                    </router-link>
                  </li>
                  <li class="my-2">
                    <router-link to="/faq/point-exchange"
                      class="font-weight-medium d-block border rounded py-2 pl-3"
                      >
                      點數及兌換機制說明
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-8 col-lg-9 order-md-1">
            <div class="accordion" id="accordionOne">
              <div class="card">
                <div class="card-header bg-warning" id="headingOne">
                  <h5
                    class="icon-bg"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <span>如何得到i玩錢點數</span>
                  </h5>
                </div>

                <div
                  id="collapseOne"
                  class="collapse show shadow-sm rounded-sm"
                  aria-labelledby="headingOne"
                  data-parent="#accordionOne"
                >
                  <div class="card-body">
                    <ul>
                      <li>凡註冊成為i玩錢會員，則可免費得到200點＄幣。</li>
                      <li>註冊會員每天登入i玩錢網站，則可免費得的10點＄幣，例如一週內天天登入則可免費得到70點點＄幣，每人僅限一次。</li>
                      <li>凡參加i玩錢遊戲，每答對一題可免費得到5點＄幣，同樣的遊戲題目每人最多回答3次。</li>
                      <li>凡參加i玩錢遊戲，每晉升到下一個Level，將免費得的1000點＄幣，例如當你從「幼幼級」晉升到「國小級」將可免費得到1000點，當你從「國小級」晉升到「國中級」則可再免費得到1000點，當你從「國中級」晉升到「高中級」則可再免費得到1000點，所以當你是高中級FQ等級，則可獲得4000點。</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header bg-success" id="headingTwo">
                  <h5
                    class="icon-bg collapsed"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <span>i玩錢點數查詢說明</span>
                  </h5>
                </div>

                <div
                  id="collapseTwo"
                  class="collapse shadow-sm rounded-sm"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionOne"
                >
                  <div class="card-body">
                    <p class="mb-6">
                      i玩錢會員可點選會員中心，可看到我的$幣幣存摺，可看到目前$幣跟商品兌換紀錄。
                    </p>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header bg-danger" id="headingThree">
                  <h5
                    class="icon-bg collapsed"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    <span>i玩錢點數使用及兌換機制説明</span>
                  </h5>
                </div>

                <div
                  id="collapseThree"
                  class="collapse shadow-sm rounded-sm"
                  aria-labelledby="headingThree"
                  data-parent="#accordionOne"
                >
                  <div class="card-body">
                    <p class="mb-6">
                      擁有i玩錢點數會員，可到「＄幣兌換中心」，可看到兌換相關商品。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'point-exchange',
  mounted () {
    require('@/assets/js/base.js')
  }
}
</script>
